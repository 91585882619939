import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { formatDateForAPI } from '@/utils/utilities';
import {
	generateAgeSpans,
	getProvinceRegions,
	calculateRegionQuantity,
	appendFeasibilityData,
	sortRegionsByName,
} from '@/utils/researchUtils';
import { Gender } from '@/types/types';
import { pick } from 'lodash';
import {
	ResearchGroup,
	LocalizationTypes,
	Research,
	ResearchStatus,
	Feasibility,
	CrossSwitches,
	Cross,
	Region,
	ContactPerson,
} from '@/types/research';
import type { RootState } from '@/store/index';
import APP_CONFIG from '@/configs/appConfig';
import hash from 'object-hash';

export const researchSlice = createSlice({
	name: 'research',
	initialState: getInitialState(),
	reducers: {
		updateResearch: (state, action: PayloadAction<Partial<Research>>) => {
			Object.assign(state, action.payload);
		},

		addContactPerson: (state) => {
			state.contactPersons.push(blankPerson());
		},

		removeContactPerson: (state, action: PayloadAction<number>) => {
			state.contactPersons = state.contactPersons.filter(
				(_, index) => index !== action.payload
			);
		},

		loadResearch: (state, action: PayloadAction<Research>) => {
			const { payload: res } = action;

			Object.assign(state, {
				...pick(res, [
					'id',
					'name',
					'status',
					'fullService',
					'client',
					'description',
					'researchToken',
					'contactPersons',
					'researchAttachments',
				]),
				temporalAttachments: [],
				deletedAttachments: [],
				updatedAttachments: [],
				researchGroups: res.researchGroups?.map((group) => ({
					...group,
					show: !res.status,
					isInvitedsLoaded: !!group.invitedPanelists.length,
					isNew: false,
					questionsChosen: [],
					uniqueLinks: group.uniqueLinks || [],
				})),
			});
		},

		updateGroup: (
			state,
			{ payload }: PayloadAction<Partial<ResearchGroup>>
		) => {
			for (const group of state.researchGroups) {
				if (group.id !== payload.id) continue;

				Object.assign(group, payload);
				if (payload.gender || payload.amountOfQuestionnaires)
					group.isRepresentativeMode = false;
			}
		},

		appendFeasibility: (
			state,
			{
				payload,
			}: PayloadAction<{
				id: string;
				feasibility: Feasibility[];
			}>
		) => {
			for (const group of state.researchGroups) {
				if (group.id === payload.id) {
					if (group.crossing.length === payload.feasibility.length) {
						group.crossing = appendFeasibilityData(
							payload.feasibility,
							group.crossing
						).filter((cross) => cross.amount || cross.feasibility);
					}
				}
			}
		},

		setAgeSpan: (
			state,
			{ payload }: PayloadAction<{ id: string; spans: [number, number] }>
		) => {
			for (const group of state.researchGroups) {
				if (group.id !== payload.id) continue;

				group.ageSpan = payload.spans;
				group.quantity.age.spans = generateAgeSpans(
					group.ageSpan,
					group.amountOfQuestionnaires
				);
			}
		},

		setCrossSwitches: (
			state,
			{ payload }: PayloadAction<{ id: string; switches: CrossSwitches }>
		) => {
			for (const group of state.researchGroups) {
				if (group.id === payload.id) group.crossSwitches = payload.switches;
			}
		},

		setUserCrosses: (
			state,
			{ payload }: PayloadAction<{ id: string; crosses: Cross[] }>
		) => {
			for (const group of state.researchGroups) {
				if (group.id === payload.id) group.userCrosses = payload.crosses;
			}
		},

		toggleRegionSwitch: (
			state,
			{ payload }: PayloadAction<{ id: string; switch: LocalizationTypes }>
		) => {
			for (const group of state.researchGroups) {
				if (group.id !== payload.id) continue;

				group.isRepresentativeMode = false;
				group.regionSwitch = payload.switch;
				group.regionsChosen =
					payload.switch === LocalizationTypes.COUNTRY
						? getProvinceRegions()
						: [];
				group.postalCodesChosen = [];
				group.quantity.region.postalCodes = [];
				group.quantity.region.regions = group.regionsChosen.map((reg) => ({
					id: reg.regionId,
					region: reg,
					value: 0,
				}));
				group.quantity.region = calculateRegionQuantity(
					group.amountOfQuestionnaires,
					group.quantity.region
				);
			}
		},

		toggleAllRegions: (state, action: PayloadAction<string>) => {
			for (const group of state.researchGroups) {
				if (group.id !== action.payload) continue;

				group.isRepresentativeMode = false;

				if (group.regionsChosen.length) {
					group.regionsChosen = [];
					group.quantity.region.regions = [];
					continue;
				}

				group.regionsChosen = getProvinceRegions();
				group.quantity.region.regions = group.regionsChosen.map((reg) => ({
					id: reg.regionId,
					region: reg,
					value: 0,
				}));
				group.quantity.region = calculateRegionQuantity(
					group.amountOfQuestionnaires,
					group.quantity.region
				);
			}
		},

		addRegion: (
			state,
			{
				payload,
			}: PayloadAction<{ id: string; region: Omit<Region, 'regionId'> }>
		) => {
			for (const group of state.researchGroups) {
				if (group.id !== payload.id) continue;

				group.isRepresentativeMode = false;

				const regionId = hash(payload.region, {
					algorithm: 'md5',
				});

				const newRegion = { regionId, ...payload.region };

				if (group.regionsChosen.some((region) => region.regionId === regionId))
					return;

				group.regionsChosen.push(newRegion);
				group.regionsChosen.sort(sortRegionsByName);
				group.quantity.region.regions.push({
					id: regionId,
					region: newRegion,
					value: 0,
				});
				group.quantity.region = calculateRegionQuantity(
					group.amountOfQuestionnaires,
					group.quantity.region
				);

				group.quantity.region.regions.sort((a, b) =>
					sortRegionsByName(a.region, b.region)
				);
			}
		},

		removeRegion: (
			state,
			action: PayloadAction<{ id: string; regionId: string }>
		) => {
			for (const group of state.researchGroups) {
				if (group.id !== action.payload.id) continue;

				group.isRepresentativeMode = false;

				group.regionsChosen = group.regionsChosen.filter(
					(region) => region.regionId !== action.payload.regionId
				);
				group.quantity.region.regions = group.quantity.region.regions.filter(
					(region) => region.id !== action.payload.regionId
				);
				group.quantity.region = calculateRegionQuantity(
					group.amountOfQuestionnaires,
					group.quantity.region
				);
			}
		},

		resetResearch: (_, action: PayloadAction<Partial<Research>>) => {
			return { ...getInitialState(), ...action.payload };
		},

		createResearchGroup: (
			state,
			action: PayloadAction<Partial<ResearchGroup>>
		) => {
			for (const group of state.researchGroups) {
				group.show = false;
			}

			state.researchGroups.push({
				...getNewResearchGroup(action.payload),
			});
		},

		removeGroup: (state, action: PayloadAction<string>) => {
			state.researchGroups = state.researchGroups.filter(
				(group) => group.id !== action.payload
			);
		},

		toggleVisibleGroup: (state, action: PayloadAction<string>) => {
			for (const group of state.researchGroups) {
				group.show = group.id === action.payload;
			}
		},

		toggleRepresentativeMode: (state, action: PayloadAction<string>) => {
			for (const group of state.researchGroups) {
				if (group.id !== action.payload) continue;

				group.isRepresentativeMode = !group.isRepresentativeMode;
				group.userCrosses = [];
				for (const key in group.crossSwitches) {
					group.crossSwitches[key] = false;
				}

				for (const key in group.quantity) {
					group.quantity[key as keyof ResearchGroup['quantity']].isAuto = true;
				}

				if (!group.isRepresentativeMode)
					group.quantity.region = calculateRegionQuantity(
						group.amountOfQuestionnaires,
						group.quantity.region
					);
			}
		},
	},
});

export const {
	updateResearch,
	createResearchGroup,
	updateGroup,
	appendFeasibility,
	setAgeSpan,
	toggleRegionSwitch,
	toggleAllRegions,
	setCrossSwitches,
	setUserCrosses,
	addRegion,
	removeRegion,
	removeGroup,
	resetResearch,
	toggleVisibleGroup,
	addContactPerson,
	removeContactPerson,
	loadResearch,
	toggleRepresentativeMode,
} = researchSlice.actions;

export const selectResearch = (state: RootState) => state.research;
export default researchSlice.reducer;

function blankPerson(): ContactPerson {
	return {
		name: '',
		surname: '',
		email: '',
		phone: '',
	};
}

function getInitialState(): Research {
	return {
		id: '',
		name: '',
		description: '',
		fullService: false,
		status: ResearchStatus.DRAFT,
		researchGroups: [getNewResearchGroup()],
		researchAttachments: [],
		temporalAttachments: [],
		deletedAttachments: [],
		updatedAttachments: [],
		researchToken: {
			id: '',
			completed: '',
			screenOut: '',
			quotaFull: '',
			qual: '',
		},
		contactPersons: [],
	};
}

export function getNewResearchGroup(
	partial: Partial<ResearchGroup> = {}
): ResearchGroup {
	return {
		show: true,
		isNew: true,
		id: crypto.randomUUID(),
		name: '',
		mobile: true,
		desktop: true,
		isRepresentativeMode: false,
		cameraRequired: false,
		dataCollecting: false,
		isBooster: false,
		amountOfQuestionnaires: APP_CONFIG.RESEARCH_GROUP_INITIAL_AMOUNT,
		gender: Gender.BOTH,
		regionSwitch: LocalizationTypes.COUNTRY,
		regionsChosen: getProvinceRegions(),
		postalCodesChosen: [],
		ageSpan: [
			APP_CONFIG.GROUP_INITIAL_AGE_START,
			APP_CONFIG.GROUP_INITIAL_AGE_END,
		],
		duration: APP_CONFIG.RESEARCH_GROUP_DEFAULT_DURATION,
		startsAt: formatDateForAPI(new Date()),
		loi: APP_CONFIG.RESEARCH_GROUP_DEFAULT_LOI,
		ir: 100,
		profilesChosen: [],
		questionsChosen: [],
		quantity: {
			gender: {
				isAuto: true,
				female: APP_CONFIG.RESEARCH_GROUP_INITIAL_AMOUNT / 2,
				male: APP_CONFIG.RESEARCH_GROUP_INITIAL_AMOUNT / 2,
			},
			region: calculateRegionQuantity(
				APP_CONFIG.RESEARCH_GROUP_INITIAL_AMOUNT,
				{
					isAuto: true,
					regions: getProvinceRegions().map((reg, idx, arr) => ({
						id: reg.regionId,
						region: reg,
						value:
							Math.floor(
								APP_CONFIG.RESEARCH_GROUP_INITIAL_AMOUNT / arr.length
							) +
							(idx < APP_CONFIG.RESEARCH_GROUP_INITIAL_AMOUNT % arr.length
								? 1
								: 0),
					})),
					postalCodes: [],
				}
			),
			age: {
				isAuto: true,
				spans: generateAgeSpans(
					[
						APP_CONFIG.GROUP_INITIAL_AGE_START,
						APP_CONFIG.GROUP_INITIAL_AGE_END,
					],
					APP_CONFIG.RESEARCH_GROUP_INITIAL_AMOUNT
				),
			},
		},
		crossing: [],
		userCrosses: [],
		invitedPanelists: [],
		excludedPanelists: [],
		omitId: false,
		isUniqueLinks: false,
		uniqueLinks: [],
		questionnaireUrl: '',
		testQuestionnaireUrl: '',
		note: '',
		crossSwitches: {
			gender: false,
			region: false,
			age: false,
		},
		...partial,
	};
}
